//初始化对象
let obj = {};
export let whiteKeys = [];
export let blackKeys = [];
//遍历拼接 sampler 对象
for(let i = 2;i<=6;i++){
    //遍历字母
    for(let j=67;j<=73;j++){
        //保存下标
        let index = j;
        //判断
        if(j === 72) index = 65;
        if(j === 73) index = 66; 
        
        //转换为字母
        let letter = String.fromCharCode(index);
        //插入数据
        let key = letter+''+i;
        obj[key] = key + '.mp3';
        //压入白键
        whiteKeys.push(key);
        //判断是否是 C D  F G  A
        if(['C','D','F','G','A'].includes(letter) ){
            let key = letter+'#'+i;
            obj[key] = letter + 's' + i +'.mp3';
            //压入黑键数组
            blackKeys.push(key);
        }
    }
}

console.log(obj);

export default {
    urls: obj,
    baseUrl: "/piano/",
}